import React from 'react'

const App: React.FC = () => {
  return (
    <div>
      <a
        className="App-link"
        href="/.netlify/functions/auth?origin=https://whoamong.us"
      >
        Edit
      </a>
    </div>
  )
}

export default App
